$prefix_after_length : 2.5rem;
.section-text-row {
	margin: 7rem 0rem;
}
.section_prefix_wrapper {
	@media (min-width: 768px) and (max-width: 1024px){
		width: 100%;
	  }
}
.section_prefix{
	position: relative;
	font-weight: lighter;
	padding-right: $prefix_after_length;
	// margin-left: 10.65rem;
	&:after{
		content: "";
		position: absolute;
		top: 50%;
		width: $prefix_after_length;
		height: 0;
		border-top: 1px solid;
		margin-left: 15px;
	}
	
}

.intra_section_margin_top{
	margin-top: 1.625rem;
}
@media screen and (max-width: 576px )  {
	.section_prefix_wrapper {
		margin-bottom: 2rem;
	}
}
.section-text-row{
@media screen and (max-width: 576px )  { 
		margin: 5rem 0rem;
}
@media (min-width:577px) and (max-width: 768px )  { 
	margin: 6rem 0rem;
}
// @media screen and (max-width: 992px )  { 
// 	margin: 7rem 0rem;
// }
// @media screen and (max-width: 1200px )  { 
// 	margin: 7rem 0rem;
// }
}