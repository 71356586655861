.footer {
    background-color: #32353B;
    margin-top: 5%;
    padding: 7.25% 0% 0.5% 0%;
    background-image: url('/assets/images/footer-logo.png');
    background-repeat: no-repeat;
    background-size: 650px;
    background-position-x: -210px;
  }
  .footer .footerlogo {
    // background-image: url('/images/footer-logo.png');
    background-repeat: no-repeat;
  }
  .footer .get-in-touch {
    margin-bottom: 50px;
  }
  .footer .get-in-touch p {
    margin-bottom: 0px;
    opacity: 0.87;
    overflow: visible;
    line-height: 30px;
    font-family: Calibre;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: #fff;
    width: 80%;
    @media screen and (max-width: 576px ) {
      width: 100%;
    } 
  }
  .footer .get-in-touch a {

    line-height: 70px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #fff !important;
    text-transform: uppercase;
    opacity: 0.87;
    text-decoration: none;
  }
  .footer .get-in-touch a img {
    margin-left: 5px;
    margin-bottom: 2px;
  }
  .footer .get-in-touch i {
    margin-left: 10px;
  }
  .footer .newsletter p {
    margin-bottom: 25px;
    opacity: 0.87;
    overflow: visible;
    line-height: 30px;
    font-family: Calibre;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: #fff;
  }
  .footer .address p {
    margin-top: 25px;
    opacity: 0.87;
    overflow: visible;
    font-family: Calibre;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: #fff;
  }
  .footer-navigation ul {
    display: flex;
    padding: 0;
    @media screen and (max-width: 576px ) {
      display: inline;
    }
  }
  .footer-navigation ul li {
  list-style: none;
  text-decoration: none;
  opacity: 0.87;
  }
  .footer .newsletter {
    border-bottom: 1px solid #707070;
    opacity: 0.87;
    
  }
  .checkbox {
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
    display: flex;
    .form-check-input:checked {
      background-color: transparent;
      border-color: #fff;
  }
  }
  .checkbox input {
    margin-top: 5px;
    opacity: 0.87;
    margin-right: 15px;
    font-size: 20px !important;
    border: 1px solid #fff;
  }
  .checkbox span {
    opacity: 0.77;
    overflow: visible;
    width: 363px;
    height: 42px;
    line-height: 20px;
    text-align: left;
    font-family: Calibre;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
  .footer .footer-second{
    margin-left: 14%;
    @media screen and (max-width: 576px ) {
      margin-left: 0%;
    } 
    @media (min-width: 768px) and (max-width: 1024px){
      margin-left: 13%;
    }
  }
  .footer .social-media-icon {
    @media (min-width: 768px) and (max-width: 1024px){
      width: 100%;
    }
  }
  .footer .social-media {
    display: flex;
    // margin-left: 3%;
    @media screen and (max-width: 576px ) {
      margin-left: 0%;
      margin-top: 2rem;
    } 
    @media (min-width: 768px) and (max-width: 1024px){
      margin-top: 2rem;
    }
  }
  .footer .social-media .fab {
    color: #fff;
    font-size: 20px;
    margin-right: 20px;
  } 
  
  .footer .copyright {
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
  }
  .career-opening {
    position: absolute;
    display: inline-block;
    height: 22px;
    width: 22px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    background-color: #AF1E34;
    border: 2px solid #ffffff;
    margin-left: -8px;
    vertical-align: top;
    top: -10px;
    right: -20px;
    @media screen and (max-width: 576px ) {
      top: -10px;
      right: 75px;
    } 
  }