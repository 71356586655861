
    .publication-image {
        // img {
        //     height: 100%;
        //     width: 412px;
        // }
    }
    .publication-text {
        span {
            margin-top: 15px;
            font-size: 16px;
            font-family: "calibre";
        }
        p {
            margin-top: 10px;
            font-size: 16px;
            font-family: "calibre";
            border-bottom: 2px solid #4D38EB;
            padding-bottom: 3%;
            
        }
        a {
            font-family: "calibre";
            margin-top: 20px;
            width: 80%;
            text-transform: capitalize;
        }
    }
    
