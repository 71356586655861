.vacancy_detail_wrap{
    // overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    // height: 800px;
    @media screen and (max-width: 767px ) {
        height: 1000px;
      } 

    strong{
        font-weight: bold !important;
    }
}

.vacancy_desc{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    transition : .5s ease all;
    opacity: 1;
    padding: 0rem 10rem 0rem 0rem;
    @media screen and (max-width: 767px ) {
    padding: 2.5% 4%;
    }
    @media (min-width: 768px) and (max-width: 1024px){
        padding: 2.5% 4%;
      }

    &.toggle{
        right: -100% !important;
        left: -100%;
        opacity: 0;
    }

}

.vacancy_form{  
    position: absolute;
    left: 100%;
    right: 0;
    width: 100%;
    transition : .5s ease all;
    opacity: 0;
    padding: 0rem 10rem 0rem 0rem;
    @media screen and (max-width: 767px ) {
        padding: 2.5% 4%;
        }
        @media (min-width: 768px) and (max-width: 1024px){
            padding: 2.5% 4%;
          }
    &.toggle{
        opacity: 1;
        left: 0 !important;
    }
}
.apply-toogle-wrap {
    // background: linear-gradient(
    //             94deg, rgba(21, 21, 21, 0.249737) 0%, rgba(21, 21, 21, 0.499037) 100%);
    background: linear-gradient( 94deg, rgba(0, 0, 0, 0.25 ) 0%, rgba(0, 0, 0, 0.5) 100%);
    padding: 2rem 1rem;

    .apply-toogle-button {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #ffffff59;
        padding-bottom: 2rem;
            button {
                font-size: 1.25rem;
                font-weight: 600;
                padding: 0.4rem 2rem;
                color: #fff;
                letter-spacing: 2px;
                border-color: #4d38eb;
                text-transform: uppercase;
                background-color: #4d38eb;
                
            }
}
.toogle-job-link{
    font-size: 1.5rem;
    // margin-left: 2rem;
    text-transform: capitalize;
}
.link-input {
    background-color: transparent;
    color: #ffffff9e;
    padding: 0.5rem;
    border: 3px solid #4d38eb;
}
}
.vacancy-detail-row {
    @media screen and (max-width: 1024px ){
        flex-direction: column-reverse;
    }
}
.copy-clipboard {
    .far {
        right: 0px;
        top: -25px;
        font-size: 16px;
        color: #18be8c;
    }
    .fa-copy:before {
        margin-right: 5px;
    }
}