// .pie_chart_section {
// 	min-height: 40rem;
// }
// .recharts-wrapper{
// 	width: auto !important;
// 	height: auto !important;
// }
.pie-filter {
    select {
        color: #fff;
    background-color: #807A56;
    padding: 3px 9px;
    border: none;
    }
    
}