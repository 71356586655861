.Teambackground {
//    background-image: url('/assets/images/teambackground.png');
   background-repeat: no-repeat;
    background-size: 550px;
    background-position-x: 78rem;
    min-height: 35rem;
    margin-top: 2rem;
    .page_summary h1 {
        margin-bottom: 2rem;
        text-transform: capitalize;
        font-size: 6rem;
    }
    .page_summary p {
        font-size: 1.5rem;
        font-weight: 400;
    }
    
}
.team-detail-row {
    @media screen and (max-width: 576px ) {
        display: flex;
        flex-direction: column-reverse;

    }
    @media (min-width: 577px) and (max-width: 768px){
        display: flex;
        flex-direction: column-reverse;
      }
      @media (min-width: 769px) and (max-width: 1024px){
        // display: flex;
        // flex-direction: column-reverse;
      }
      .team-detail-description{
        @media (min-width: 577px) and (max-width: 768px){
            width: 100%;
          }
      }
     
}
.team-detail-image {
    img {
        // position: absolute;
        // top: -140px;
        @media screen and (max-width: 576px ) {
            position: relative;
            top: 0px;
            margin-bottom: 2rem;
        }
        // @media (min-width: 577px) and (max-width: 768px){
        //     top: -7rem;
        //     left: 24rem;
        //     // width: 100%;
        //     // position: relative;
        //     // top: 0px;
        //     // margin-bottom: 2rem;
        //   }
        //   @media (min-width: 769px) and (max-width: 1024px){
        //     top: 0px;
        //     // width: 100%;
        //     // position: relative;
        //     // top: 0px;
        //     // margin-bottom: 2rem;
        //   }
        
    }   
}
.team-detail-icons{
   
    a img {
        &:hover{
            opacity: 0.5;
        }
    }
  }
