.project-input-column {
    .project-input-wrapper {
        display: flex;
        input, select {
            padding: 1.5%;
            width: 60%;
            border: 1px solid #fff;
            border-radius: 6px;
            @media screen and (max-width: 576px ) {
                padding: 3%;
                width: 100%; 
            }
            &::placeholder{
                color: #fff;
            }
        }
        span {
            position: relative;
            .fas {
                position: absolute;
                right: 15px;
                top: 18px;
            }
        }
    }
} 
.project-count {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 576px ) {
		display: inline;
        margin-top: 1rem; 
	}
}