// override bs 
.nav-tabs{
	border-bottom: 0;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #FFF;
    background-color: #AF1E34;
    border-color: transparent;
	border-radius: 10px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: transparent;
    isolation: isolate;
}
a {
    text-decoration: none;
}


// Custom Stylings 
.theme_bg{
    background: #061728;
}

//floating form css modify
.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label{
    font-size: 1.2rem;
}

// datepicker icon color 
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

// Alert Customization 
.alert-success {
    color: #00CC83;
    font-size: 1.25rem;
    font-weight: 600;
}
.alert-danger {
    color: #FB4D52 !important;
    font-size: 1.25rem;
    font-weight: 600;
    &::first-letter {
        text-transform: uppercase;
    }
}
p{
    a{
        color: #6a9eff;
        text-decoration: underline;
    }
}
.btn-success {
    color: #fff;
    background-color: #18be8c;
    border-color: #18be8c;
}
.btn-success:hover {
    color: #fff;
    background-color: #1faf7c;
    border-color: #1faf7c;
}
.btn-info {
    color: #fff;
    font-size: 1.25rem;
    padding: 5px 10px;
    text-transform: capitalize;
    
}
.btn-info:hover {
    color: #fff;
}


// Logo Animation Css 
.tagline_wrap_outer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo_animation{
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    width: 100%;
    z-index: -1;
}

p{
    em{
        font-size: 76%;
    }
    img {
        max-width: 100% !important;
        height: auto !important;
    }
}


.bg-info{
    background-color: #1faf7c !important;
    border-color: #1faf7c;
}

strong{
    font-weight: bold !important;
}