// React Paginate Customized Styling 
.pagination{
	margin-top: 2rem;
	padding: 0;
	display: flex;
	justify-content: center;

	.previous {
		a{
			border-radius: 30%;
			padding: 0.25rem 0.75rem;
			display: block;
			width: auto;
			line-height: 42px;		
		}
	}
	.next {
		a{
			border-radius: 30%;
			padding: 0.25rem 0.75rem;
			display: block;
			width: auto;
			line-height: 42px;		
		}
	}
	li{
		display: inline-block;
		margin-right: 25px;

		a{
			position: relative;
			display: inline-block;
			height: 56px;
			width: 56px;
			line-height: 49px;
			text-align: center;
			color: #fff;
			border-radius: 50%;
			font-size: 22px;
			border: 2px solid #ffffff;

			&:hover{
				background: #cccccc47;
				color: #fff !important;
				
			}

			&:disabled{
				&:hover{
					cursor: disabled;
					background: #eee;
				}
			}
		}
		&:active a, &.active a {
			// background: rgb(32,81,130);
			background: linear-gradient(
				180deg, rgba(2, 8, 20, 0.80) 0%, rgba(2, 8, 20, 0.20) 50%, rgba(2, 8, 20, 0.80) 100%);
		}
	}
}