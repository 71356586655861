.featured_blog_wrapper{
	img {
		filter: brightness(.4);
	}
	.publication-tag {
		top: 10px;
		left: 20px;
		font-size: 1.25rem;
		background-color: #18be8c;
		color: #fff;
		border-radius: 3px;
		padding: 1px 10px;
        text-transform: capitalize;
	}
	.blog_content{
		padding-left: 6rem;

		.blog_content_wrap{
			// border-top: 1px solid #fff;

			.blog_date{
				text-align: right;
				// color: #ada579;
				// font-weight: 600;
				// text-transform: capitalize;
			}
		}
	}
	.author {
		color: #ada579;
		font-weight: 600;
		text-transform: capitalize;
		display: flex;
		border-top: 1px solid #fff;
    	padding-top: 1rem;
	}
}

@media screen and ( max-width: 767px ) {
	.featured_blog_wrapper{
		.blog_content{
			padding-left: 0;
			margin-top: 2rem;
		}
	}
}
