.blog_image_wrap_d2{
	img{
		max-width: 100%;
	}

}
.blog_sum_wrap_d2{
	P{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}
}
.blog_content_rt {
	p{
		a{
			color: #6a9eff;
			text-decoration: underline;
		}
	}
}