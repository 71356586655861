.tab_item_wrapper{
	background-repeat: no-repeat;
	background-size: cover ;
	background-position: 50% 50% ;
	min-height: 34rem;
	overflow: hidden;

	img {
		width: 100%;
	}

	&:hover{
		.tab_item_desc_wrap{
			transform: translateY(0);
			background: rgba(1, 26, 56, 0.93);
		}

		.team_opacity_wrap{
			.tab_item_desc{
				opacity: 1;
			}
		}
	}

	// &.position-relative{
		.tab_item_desc_wrap{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			transition: .5s ease all;
			background: rgba(1, 26, 56, 0.84);
			transform: translateY(9rem);
			img {
				width: auto;
			}
			
		}

		.team_opacity_wrap{
			.tab_item_desc{
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2; /* number of lines to show */
				-webkit-box-orient: vertical;
				margin: 0;
				opacity: 0;
				transition: .5s ease all;
				min-height: 60px;
			}
		}
	// }
}