.section-score-osm-wrapper {
    margin-bottom: 4rem;
    .counter-box {
        .counter{
            font-family: "Raleway";
            font-weight: 500;
        }
        .counter_label{
            
        }
    }
}