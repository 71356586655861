.event-row {
	margin: 2.5rem 0rem;
	.event-col {
		@media screen and (max-width: 576px ){
		margin-bottom: 2rem !important;
	}
	}
}
.section_dark{
	// background: #181B23;
	padding: 3rem 0;
}