.accordion {
    background-color: transparent;
    .accordion-item {
        background-color: transparent; 
        border: none;
        .social-media {
            display: flex;
            
            .fab {
                margin-right: 1rem;
            }
        }
    }
}
.accordion-button:not(.collapsed) {
    
 background-color: transparent; 
 
}
.accordion-button:focus {
   
 box-shadow: none; 
}