$box_border : 1px solid #807A56;
.score-box{
	border: $box_border;
	border-right: none;

	&:last-of-type{
		border-right: $box_border;
	}
	@media screen and (max-width: 576px )
	 {
		border: 1px solid #807A56;
		}
		@media screen and (max-width: 768px )
	 {
		border: 1px solid #807A56;
		}
	  
}

.counter{
	font-size: 3rem;
	font-weight: normal;
	font-family: 'Bebas Neue', cursive;

	i{
		font-family: 'FontAwesome';
	}

	small{
		font-weight: normal;
		font-size: 1rem;
		display: block;
	}
}

.section_link {
    position: absolute;
    border: $box_border;
	width: calc(100% + 2px);
    left: -1px;
	text-align: right;
	font-size: 0.8rem;
	text-decoration: none;

	span{
		padding: 0.3em 0.5rem;
    	margin-left: 0.3rem;
		border-left: $box_border;
		height: 100%;
		display: inline-block;
	}

	&:hover{
		cursor: pointer;
	}
}