.blog_date {
    display: flex;
    justify-content: space-around;
} 
.bloglisting-img img {
    border: 1px solid #685D45;
    height: 22rem;
    object-fit: cover;
}
.blog-author-date {
    color: #ada579;
    font-weight: 600;
    text-transform: capitalize;
    // border-top: 1px solid #fff;
    // padding-top: 1rem;
}