.projectfeature-wrapper {

    margin-bottom: 5rem;
    .feature-image {
        img {
            width: 100%;
            height: 35rem;
            object-fit: contain;
            @media screen and (max-width: 576px ) {
                display: none;
              } 
        }
    }
    .feature-content-wrapper {
        display: flex;
        align-items: center;
        .feature-content {
            // padding-left: 4rem;
            .sectiontextcontent-paragraph {
                p{
                    margin-top: 2rem;
                }
            }
        }
    }

    &.row-reverse{
        .feature-content-wrapper{
            .feature-content{
                padding-left: 0;
            }
        }
    }
}