
    .newsletter-form {
      // width: 50%;
      // @media screen and (max-width: 576px ) {
      //   width: 100%;
      // } 
      // @media (min-width: 768px) and (max-width: 1024px){
      //   width: 100%;
      // }
      .form-group{
        // @media screen and (max-width: 320px){
        //   display: inline !important;
        // }
        // @media screen and (max-width: 375px){
        //   display: inline !important;
        // }
        .form-control{
          
          border: 2px solid #8A8D91;
          
        }
        .newsletter-button{
          // right: 104px;
          // display: flex;
          // align-items: center;
          // @media screen and (max-width: 320px){
          //   right: -192px;
          //   bottom: 42px;
          // }
          // @media (min-width: 321px) and (max-width: 375px){
          //   right: -246px;
          //   bottom: 42px;
          // }
            position: absolute;
            border-radius: 3px;
            font-size: 1rem;
            color: #FFF;
            border-color: #AF1E34;
            font-family: 'Calibre';
            background-color: #AF1E34;
            right: 5px;
            top: 5px;
         
        }
      }
      
    }
  
