.vacancylisting-wrapper {
    background: linear-gradient( 
    293.42deg, rgba(21, 21, 21, 0.4) 0%, rgba(21, 21, 21, 0) 107.02%);
    border: 2px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(17px);
    border-radius: 5px;
    .vacancy-text {
        margin-left: 1rem;
        margin-bottom: 1rem;
        a {
            font-size: 2rem;
            font-weight: 500;
            margin-left: 0rem;
            @media screen and (max-width: 1024px ){
				font-size: 1.7rem;
			}
			@media (min-width: 1025px) and (max-width: 1400px){
				font-size: 1.5rem;
			  }
        }
    }
}

.jd_short{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}