.ad_image{
    margin: 0;
    position: relative;
    overflow: hidden;
    transition: .5s ease all;
    
    img{
        transition: .5s ease all;
        width: 100%;
    }

    .blog-showcase {
        position: absolute;
        bottom: 0px;
        background: rgba(1, 26, 56, 0.84); /*rgba(77,56,235,0.8);*/
        width: 100%;
        padding: 0% 2%;
        overflow: hidden;
        transition: .95s ease;
        transform: translateY(40%);
            
        .blog-showcase-description{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
            margin: 0;
            opacity: 0;
            transition: .5s ease all;

            p{
                margin: 0;
            }
        }

        a{
            @media screen and (max-width: 576px) {
                font-size: 1rem;
            }
            @media (min-width: 768px) and (max-width: 1024px){
                font-size: 1rem;
                line-height: 1;
              }
        }
    }

    &:hover{
        img{
            transform: scale(1.12);
        }

        .blog-showcase{
            transform: translateY(0);
            background: rgba(1, 26, 56, 0.93);
            transform: translateY(0);
            .blog-showcase-description{
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: 576px) {
        margin-bottom: 3rem;
    }

    img{
        width: 100%;
        height: auto;
        border: 1px solid #807A56;
    }

    &.ad_image_link{
        display: inline-block;
        // padding: 40px 0;
        text-align: left;
        width: 100%;
        a {	
            img {
                width: auto;
                border: none;
            }
        }
    }
}