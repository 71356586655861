.vision-row {
    margin-bottom: 10rem;
    @media screen and (max-width: 576px ) {
       
        margin-left: 0;
        margin-right: 0;
    }
    @media screen and (max-width: 768px ) {
      justify-content: left;
    margin-left: 1rem;
  }
}

