.projectlisting-wrapper {
    background: rgba(0, 0, 0, 0.35);;
    border: 1px solid #807A56;
    backdrop-filter: blur(17px);
    padding-bottom: 2rem;
    border-radius: 5px;
    .project-image {
        margin-bottom: 1rem;
        padding: 1rem 1rem 0rem 1rem;
    }
    .project-text {
        padding: 1rem;
        a {
            // font-size: 1.5rem;
            // font-weight: 500;
            margin-left: 0rem;
            // text-decoration: none;
            display: flex;
            align-items: center;
            margin-left: 10px;
            word-break: break-word;
        }
        img {
            height: 4rem;
            object-fit: cover;
        }
    }
    // a {
    //     margin-left: 1rem;
    // }
    p{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin: 0rem 1rem;
        
    }
}
.project-item-column {
    @media (min-width: 768px) and (max-width: 1024px){
        width: 50%;
      }
}