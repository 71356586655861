

.page_summary{
	padding-top: 0.5rem !important;

	@media screen and (min-width: 1200px ) {
		width: 60%;
	}
}

.page_header{
	@media screen and (min-width: 1200px ) {
		width: 70%;
	}
}
.page_header_wrapper {
	margin-top: 10rem }
@media screen and (max-width: 768px )  {
	.page_header_wrapper {
		margin-top: 7rem }
}
