sup {
	vertical-align: super;
	font-size: 0.875rem;
	font-weight: normal;
	margin-left: -0.5rem;
}

.osm_module{
	margin-bottom: 5rem !important;
	position: relative;
	h2{
		margin-bottom: 1rem;
		sup{
			position: absolute;
			left: -1.25rem;
		}	
	}
	
		
}

.counter_label{
	span{
		margin-left: 0.5rem;
		font-weight: bold;
	}
}

.counter-box{
	border-left: 2px solid #fff;
	padding-left: 2rem;
	margin-top: 5rem;

}
.osm_summary{
	padding-top: 0.5rem !important;

	@media screen and (min-width: 1200px ) {
		width: 80%;
	}
}
@media screen and (max-width: 576px ) {
	.osm_module_wrapper {
	padding: 0 1rem;} 
}
