.digital-volunteers-wrapper {
    background-color: #181B23;
    background-image: url('/assets/images/osm.svg');
    background-repeat: no-repeat;
    background-size: 700px;
    background-position-x: right;
    background-position-y: bottom;
    padding-bottom: 5rem;
    @media screen and (max-width: 576px )  { 
		background-size: 300px;
	}
}