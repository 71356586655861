.slider-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;

    p{
        display: none;
        line-height: 36px;

        &:first-of-type{
            display: block;
        }
    }
}
.carousel-item {
    img {
        // height: 500px;
        object-fit: cover;
    }
}
.carousel-indicators {
    bottom: -40px;
   
}
.hidden-class {
    @media screen and (max-width: 576px ) {
        display: none;
      }
}
.publication-carousel-tag{
        top: 10px;
		left: 25px;
		font-size: 1.25rem;
		background-color: #18be8c;
		color: #fff;
		border-radius: 3px;
		padding: 1px 10px;
        text-transform: capitalize;
    }
.carousel-indicators [data-bs-target] {
    border-radius: 50%;
    width: 10px;
    height: 10px;
}