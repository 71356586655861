// Mixin for create variety of animations 
@mixin animateCreate($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}

	@-moz-keyframes #{$name} {
		@content;
	}

	@keyframes #{$name} {
		@content;
	}
}

// Mixin for create animation classes effects
@mixin animate($content){
	-webkit-animation: #{$content};
	-moz-animation: #{$content};
	animation: #{$content};
}


.bubble_item{
	transition: .2s ease all;
	position: relative;
	&:hover{
		transform: scale(1.2) !important;
		cursor: pointer;

		.bubble_outer{
			background: radial-gradient(#4E1BCF,#CE2D9B);
			z-index: 999;
		}
	}
	// Layer css 
	.bubble_outer{
		background: radial-gradient(closest-side,#3484BE 20%, #CE2D9B 100%);
		margin: 30px;
		display: inline-block;
		border-radius: 50%;

		.span{
			position: absolute;
			top: 40%;
			right: 0;
			left: 0;
			text-align: center;
			font-size: 1.1rem;

			span{
				margin-top: 1rem;
			}
		}
	}
}

//Animation patterns
@include animateCreate('animateBubble'){
	0% {
		transform: translate(10px);
	}
	100% {
		transform: translate(50px);
	}
}

@include animateCreate('animateBubbleSide'){
	0% {
		transform: translate(10px,20px);
	}
	100% {
		transform: translate(50px,70px);
	}
}

@include animateCreate('sideWays'){
	0% { 
		transform: translateX(0);
	}
	100% { 
		transform: translateX(20px);
	}
}

// Various type of animation create 
.animate_t1{
	@include animate("animateBubble 3s ease-in-out infinite alternate");
}
.animate_t2{
	@include animate("sideWays 5s ease-in-out infinite alternate");
}
.animate_t3{
	@include animate("animateBubbleSide 7s ease-in-out infinite alternate");
}
