.bg_danger{
	background-color: red;
}
.about-feature-description {
	width: 90%;
}

.about-second-column{
	margin-top: 5rem;
}
.yilab-paragraph {
	width: 80%;
	@media screen and (max-width: 1024px ) {
		width: 100%;
	}

}
.feature-row{
@media (min-width: 767px) and (max-width: 1200px){
	display: flex;
	flex-wrap: inherit;
  }
}