.speakatevent-wrapper {
    .modal-body {
        padding: 2.5rem;
        @media screen and (max-width: 576px )  { 
            padding: 1rem;
        }
        .form-control{
            font-size: 18px;
        }
        .form-check-label {
            font-size: 18px;
        }
    }
}

// for multi step form 
.step_form_wrap{
    overflow: hidden;

    .team_wrapper, .dept_wrapper{
        text-align: center;
        transition: .2s ease all;
        border: 4px solid transparent;
        position: relative;

        img{
            max-height: 200px;
            max-width: 100%;
            width: auto;
        }

        &:hover, &.selected{
            border: 4px solid rgb(27, 160, 22);
            transform: scale(0.99);
            cursor: pointer;
        }
        &.selected{
            &:after{
                position: absolute;
                content: "\f058";
                bottom: 5px;
                right: 5px;
                font-family: "Font Awesome 5 Free";
                color: rgb(59, 228, 53);

            }
        }
    }

    .dept_wrapper{
        border: 2px dashed rgb(63, 58, 102);

        &:hover, &.selected{
            border: 2px solid rgb(27, 160, 22);
            transform: scale(0.99);
            cursor: pointer;
        }
        &.selected{
            &:after{
                position: absolute;
                content: "\f058";
                bottom: 5px;
                right: 5px;
                font-family: "Font Awesome 5 Free";
                color: rgb(59, 228, 53);

            }
        }
    }
}

.team_select p{
    font-size: 1.2rem;
}

.change_btn:hover{
    cursor: pointer;
}