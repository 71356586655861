.loading_wrap{
	img{
		max-width: 5%;

		@media screen and (max-width: 576px){
			max-width: 20%;
		  }
		  @media (min-width: 577px) and (max-width: 768px){
			max-width: 15%;
		  }
		  @media (min-width: 769px) and (max-width: 1024px){
			max-width: 15%;
		  }
	}
}