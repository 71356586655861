// @overriding bs
 .form-control {
   
    border: 2px solid #5B7288;
    background: linear-gradient( 
94deg, rgba(21, 21, 21, 0.249737) 0%, rgba(21, 21, 21, 0.499037) 100%);
    text-transform: capitalize;
}
.cv-upload-wrapper {
    border: 2px solid #4D38EB;
    border-radius: 0.25rem;
    input {
        display: none;
        &::placeholder{
            color: #4D38EB;
        }
    }
    label {
        background-color: #4D38EB;
        color: #fff;
        padding: 1rem;
    }
}
