.item{
	background: rgba(0, 0, 0,  0.35);
	padding: 1rem 1rem 1rem;
	height: 100%;
	width: 100%;
	border: 1px solid #807A56;
	vertical-align: bottom;
	word-break: break-word;
	transition: font-size .4s;
	display: flex;
	align-items: center;
	justify-content: space-between;
	a{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
	&:hover{
		font-size: 1.4rem;
		}
}

.item_wrapper{
	background-repeat: no-repeat;
    background-size: 20%;
    background-position: 90% 10%;
	@media screen and (max-width: 576px ){
		  margin-top: 1rem;
		  flex: auto;
		}
	@media  (min-width:577px) and (max-width:768px) {
		margin-top: 1rem;
	}  
}
.project-logo-image {

	img{
		height: 6rem;
		object-fit: contain;
	}
}

 