.bubble_wrap{
	line-height: 1rem;
	 
	-webkit-column-count: 5;
	-webkit-column-gap:   0px;
	-moz-column-count:    5;
	-moz-column-gap:      0px;
	column-count:         5;
	column-gap:           0px;
	// background-color: #ccc;
}