  .yilab-container {
	padding: 2.5% 7%;
	@media screen and (max-width: 576px )  { 
		padding: 2.5% 4%;
	}
}
.header-menu-col {
	display: flex;
}
.main-navigation {
	margin-top: 15px;
}
.main-menu {
	display: flex;
	list-style: none;
	justify-content: space-evenly;
	align-items: center;
}

.menu-item {
	margin-right: 3rem;
	@media (min-width: 768px) and (max-width: 1024px){
		margin-right: 2rem;
	  }
	  .dropdown{
		.dropdown-menu.show {
			background-color: #112130;
			border-color: rgba(0, 0, 0, 0.15);
			transform: translateX(0);
		}
		.dropdown-toggle::after {
			margin-left: 0.5rem;
		}
	}
}
.menu-item a{
	white-space: nowrap;
	text-align: left;
	font-family: "gotham";
	font-weight: normal;
	font-size: 14px;
	color: rgba(255, 255, 255, 1);
	text-transform: uppercase;
	text-decoration: none;
	list-style: none;
	align-items: center;
	display: flex;
	@media (min-width: 992px) and (max-width: 1200px){
		font-size: 12px;
	  }
}
.header-menubar {
	display: flex;
	justify-content: flex-end;
	
}

.offcanvas-end {
	width: 100%;
	background-color: #08121c;  
}

.openbtn:hover{
	cursor: pointer;
}

// Hamburger .main-navigation-hamburger > .main-menu-ham

.main-navigation-hamburger{
	ul{
		padding: 0;

		@for $i from 1 through 10 {
			li:nth-child(#{$i}) {
				transition-delay: 0.2s;
				transition-duration: calc(#{$i} * 0.1s);
			}
		}

		li{
			list-style-type: none;
			margin-top: 0.5rem;
			transform: translateX(100px);
			opacity: 0;
			margin-bottom: 3rem;

			
			a{
				font-size: 1.25rem;
				margin: 2rem;
			}

			&:hover{
				padding-left: 10px;
				transition-delay: 0s;
				transition-duration: 0.2s;
				
			}
		}
	}
}

.offcanvas.show{
	.offcanvas-header {
		padding: 2rem 2rem;
	}
	.offcanvas-body {
		a{
			font-size: 1.6rem;
			text-align: center;
			@media screen and (max-width: 576px ) {
				font-size: 1.25rem;
				text-align: center;
			}
		}
	}
	
}
.offcanvas-outline {
	border: 1px solid #00A8FF;
	border-radius: 6px ;
	width: 100%;
	&:hover {
		background-color: #030F26;
	}
	a{
		&:hover {
			color: #00A8FF;
		}
	}
	img {
		&:hover {
			path {fill: #00A8FF;}
		}
	}
}

.offcanvas-icons {
	@media screen and (max-width: 576px )  { 
		height: 80px;
		width: 80px;
	}
}