.general-info {
    font-weight: 300;
}
.digitalvolunteer-wrapper {
   
    .modal-body {
        padding: 1rem 2.5rem 2rem 2.5rem;
        @media screen and (max-width: 576px )  { 
            padding: 1rem;
        }
    }
}
.form-check-input:checked {
    background-color: transparent;
}
.form-check-input {
    border: 2px solid #fff;
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 0.5rem;
}
.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    color: #fff;
    // background: transparent url();
    border: 0;
    border-radius: .25rem;
    opacity: .5;
}
.digitalvolunteer-wrapper {                
    .form-control{
        font-size: 18px;
    }
    
 }
 .form-check-label {
     font-size: 18px;
 }