.creative_group_section{
	background-color: #141C2A;
}
.join-team-row {
	margin: 5rem 0rem;
}
.nav-tab{
	.nav-item {
		margin-right: 1.8rem;
	}
}
.team-form-wrapper {
	.team-form {
		display: flex;
		::placeholder {
			color: #fff;
		}
		.form-control {
			border: 2px solid #4D38EB;
			margin-right: 20px;
			&:focus {
                box-shadow:  0 0 0 0.25rem rgba(53, 71, 230, 0.5);
            }
		}
	}
	.form-floating {
		margin-bottom: 1rem;
		margin-right: 20px;
		.form-control {
			overflow: hidden;
			border: 2px solid #4D38EB;
			border: 2px solid #5B7288;
    background: linear-gradient( 
94deg, rgba(21, 21, 21, 0.249737) 0%, rgba(21, 21, 21, 0.499037) 100%);
		}
	}
}
