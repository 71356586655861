.st0 {
    fill: #ffffff;
  }
  .not-found {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .not-found-image {
    max-height: 500px;
    max-width: 50%;
  }
  .not-found-message {
    font-size: 24px;
    font-weight: 600;
  }
  .eyes {
    animation: blink 3s linear infinite;
  }
  .Rolly {
    animation: RollAround 5s ease-in-out infinite;
  }
  .Shaddow {
    animation: MoveShaddow 5s ease-in-out infinite;
    position: relative;
    top: -12px;
    z-index: -1;
  }
  @keyframes blink {
    0% {
      transform: scale(1, 1);
    }
    42% {
      transform: scale(1, 1);
    }
    45% {
      transform: scale(1, 0.4) translateY(100px);
    }
    48% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  @keyframes RollAround {
    0% {
      transform: translateX(-100px) rotateZ(7deg);
    }
    50% {
      transform: translateX(100px) rotateZ(-7deg);
    }
    100% {
      transform: translateX(-100px) rotateZ(7deg);
    }
  }
  @keyframes MoveShaddow {
    0% {
      transform: translateX(-120px);
    }
    50% {
      transform: translateX(120px);
    }
    100% {
      transform: translateX(-120px);
    }
  }
  .error-button {
      background-color: #4d38eb;
      padding: 0.5rem 1rem;
      color: #fff;
      border-radius: 6px;
    }