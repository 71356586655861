.publication-detail-wrapper {
        margin-top: 5rem;
        .publication-image {
            img { 
                object-fit: contain;
                width: 100%;
            }
            .publication-image-content{
                margin-top: 1rem;
            }
        }
        .publication-content {
            background: rgb(21,21,21);
            background: linear-gradient(94deg, rgba(21,21,21,0.2497373949579832) 0%, rgba(21,21,21,0.4990371148459384) 100%);
            border-radius: 5px;
            padding: 4rem;
            .icons {
                display: flex;
                margin-bottom: 4rem;
                img {
                    margin-right: 25px;
                }
                .accordion{
                    .accordion-item {
                        // background-color: transparent;
                        // border: none;
                        .accordion-body {
                            padding: 1rem 0rem;
                        }
                        .accordion-button {
                            width: 0%;
                            padding: 0rem;
                            &::after{
                                display: none;
                            }
                            :focus{
                                // border-color: transparent;
                                // box-shadow: none;
                            }
                        }
                    }
                    
                }
                
            }
            .publication-specification{
                margin-bottom: 4rem;
                p{
                    font-size: 16px;
                    font-family: "calibre";
                    margin-bottom: 1rem;
                    a{
                        margin-left: 15px;
                    }
                }
            }
            .publication-citation {
                margin-bottom: 4rem;
                ul {
                    padding-left: 0rem;
                    li {
                        list-style: none;
                        margin-bottom: 1rem;
                        .fa {
                            font-size: 15px;
                            margin-right: 15px;
                            color: #4D38EB;
                        }
                        a {
                            text-decoration: underline;
                            font-size: 16px;
                            font-family: "calibre";
                        }
                    }
                }
            }
            .publication-keywords{
                ul {
                    display: flex;
                    padding: 0;
                    li {
                        list-style: none;
                        margin-right: 15px;
                        a{
                            padding: 6px 12px;
                            background-color: #4D38EB;
                            border-radius: 3px;
                            font-size: 14px;
                            font-family: "calibre";
                        }
                    }
                    
                }
            }
        }
     
    
}
.pubication-share-social{
    background: linear-gradient(
94deg, rgba(21, 21, 21, 0.249737) 0%, rgba(21, 21, 21, 0.499037) 100%);
    width: 100%;
    padding: 1rem 1rem;
    p {
        font-size: 1.35rem;
        font-weight: 400;

    }
    .icon {
        font-size: 1.7rem;
    }
}
.publication-stat {
    background: linear-gradient(
        94deg, rgba(21, 21, 21, 0.249737) 0%, rgba(21, 21, 21, 0.499037) 100%);
            width: 100%;
            padding: 1rem 1rem;
    p {
        font-size: 1.35rem;
        font-weight: 400;
    }
    .detail-panel{
        .number{
            font-size: 1.25rem;
            font-weight: 600;
        }
        span {
            font-size: 1.25rem; 
        }
    }
}
.download-wrapper {
    background: linear-gradient(
        94deg, rgba(21, 21, 21, 0.249737) 0%, rgba(21, 21, 21, 0.499037) 100%);
            width: 100%;
    .download-button {
        background-color: #4D38EB;
        border-color: #4D38EB;
    }
    .read-button {
        background-color: transparent;
        border: 2px solid #4D38EB;
        color: #fff;
    }
}
.publication-detail-info {
    background: linear-gradient(
        94deg, rgba(21, 21, 21, 0.249737) 0%, rgba(21, 21, 21, 0.499037) 100%);
    p{
        font-size: 1.25rem;
        font-weight: 600;
        a{
            margin-left: 1rem;
            word-break: break-all;
        }
    }
}