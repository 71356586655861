.list_item_title{
	font-size: 1rem;
	font-weight: bold;
}
.list_item_paragraph {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
.list_item_wrapper{
	// background: #262930;
	// border: 1px solid #fff;
	@media screen and (max-width: 576px )
	 {
		margin-bottom: 2rem;
		}
}
.event-wrapper {
	border: 1.5px solid #373A40;
	background: #262930;
	.event-image-wrapper {
		
		img {
			height: 138px;
			width: 100%;
			object-fit: cover;
	}
	}
	.list_item_info {
		p {
			margin-bottom: 00.875rem;		
		}
		@media screen and (max-width: 576px ){
		margin-top: 0rem !important;
		}
		a {
			cursor: pointer;
			transition: font-size .2s;
			border: 1px solid #807A56;
			width: fit-content;
			padding: 0.2rem 0.39rem;
			font-size: 1rem;
			border-radius: 4px;
			&:hover{
				font-size: 1.2rem;
			}
		}
	}		
}
.event-tag {
	background-color: #18be8c;
}