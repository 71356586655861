.normal-effect {
    color: #fff;
    position: relative;
    font-size: 16px;
    line-height: 18px;
    font-weight: 900;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    vertical-align: middle;
    text-decoration: none;
    text-transform: uppercase;
    padding: 15px 20px;
    transition: all 0.2s ease;
    width: fit-content;
    overflow: visible !important;
    &:hover{
      color: #fff;
    }
    svg {
        margin-left: 5px;
        margin-bottom: 3px;
    }
    @media screen and (max-width: 576px )  { 
		font-size: 1rem;
    letter-spacing: 0.1em;
}
}

  .normal-effect:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 28px;
    background: #AF1E34;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease;
    z-index: -1;
  }
  .normal-effect svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #fff;
    transform: translateX(-5px);
    transition: all 0.3s ease;
  }
  .normal-effect:hover:before {
    width: 100%;
  }
  .normal-effect:hover svg {
    transform: translateX(0);
  }
  .link-with-arrow:active {
    transform: scale(0.96);
  }


  // for white effect 
  .link-with-arrow {
    .white-effect {
      position: relative;
      font-size: 16px;
      line-height: 18px;
      font-weight: 900;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      vertical-align: middle;
      text-decoration: none;
      text-transform: uppercase;
      padding: 15px 20px;
      transition: all 0.2s ease;
      svg {
        margin-left: 5px;
        margin-bottom: 3px;
      }
      @media screen and (max-width: 576px )  { 
        font-size: 1rem;
    }
    }
    
      .white-effect:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      border-radius: 28px;
      background: #FFF;
      width: 50px;
      height: 50px;
      transition: all 0.3s ease;
      z-index: -1;
      }
      .white-effect svg {
      position: relative;
      top: 0;
      margin-left: 10px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: #fff;
      stroke-width: 2;
      transform: translateX(-5px);
      transition: all 0.3s ease;
      }
      .white-effect:hover:before {
      width: 100%;
      background: #FFF;
      }
      .white-effect:hover svg {
      transform: translateX(0);
      }
      .link-with-arrow:active {
      transform: scale(0.96);
      }
  }

   // for no effect 
   .link-with-arrow {
    .no-effect {
      position: relative;
      font-size: 16px;
      line-height: 18px;
      font-weight: 900;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      vertical-align: middle;
      text-decoration: none;
      text-transform: uppercase;
      padding: 15px 20px;
      transition: all 0.2s ease;
      svg {
        margin-left: 5px;
        margin-bottom: 3px;
      }
      @media screen and (max-width: 576px )  { 
        font-size: 1rem;
    }
    }
    
      // .white-effect:before {
      // content: "";
      // position: absolute;
      // top: 0;
      // left: 0;
      // display: block;
      // border-radius: 28px;
      // background: #FFF;
      // width: 50px;
      // height: 50px;
      // transition: all 0.3s ease;
      // z-index: -1;
      // }
      .no-effect svg {
      position: relative;
      top: 0;
      margin-left: 10px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: #fff;
      stroke-width: 2;
      transform: translateX(-5px);
      transition: all 0.3s ease;
      }
      .no-effect:hover:before {
      width: 100%;
      background: #FFF;
      }
      .no-effect:hover svg {
      transform: translateX(0);
      }
      .link-with-arrow:active {
      transform: scale(0.96);
      }
  }