.image-text {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  @media screen and (max-width: 576px )
	 {
		margin-bottom: 2rem;
    justify-content: flex-start;
		}
}
.showcase-image {
  position: relative;
  .tags{
    position: absolute;
    background: #4D38EB;
    top: 10px;
    left: -15px;
    padding: 2px 20px;
    font-size: 14px;
    font-family: "calibre";
    text-transform: capitalize;
  }
    img{
    height: 525px;
    width: 100%;
    object-fit: cover;
  }
}
.showcase-image-wrapper img {
    height: 250px;
    width: 100%;
    object-fit: cover;
  }
  .pub_image_wrapper {
    img {
      transition: .5s ease all;
      &:hover{
        transform: scale(0.97);
      }
    }
  }
  .publication-feature-wrapper {
    background: #040b12;
    border: 1px solid #807A56;
    padding-bottom: 2rem;
    border-radius: 5px;
    .publication-feature-text{
      p{
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }
  }
.publication-landing-tag {
    top: 10px;
		left: 25px;
		font-size: 1.25rem;
		background-color: #18be8c;
		color: #fff;
		border-radius: 3px;
		padding: 1px 10px;
    text-transform: capitalize;
}