
.section-box{
	@media screen and (min-width:992px){
		padding: 0 2.5rem;
	}

	.box-wrapper{
		padding: 2.5rem;
		background: #0e15248f;
		height: 100%;
		border: 1px
 		solid #807A56;
		 border-radius: 4px;
		.box-title {
			@media screen and (max-width: 1024px ){
				font-size: 2rem;
			}
			@media (min-width: 1025px) and (max-width: 1400px){
				font-size: 1.5rem;
			  }
		}
		.btn-info {
			color: #fff;
			&:hover{
				border-color: #706A4B;
				background-color: #706A4B;
			}
		}
	}
}
@media screen and (max-width: 576px ) {
	.section-box {
	  margin-bottom: 3rem;
	}
  }