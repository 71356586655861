.projectdonor-image {
    display: flex !important;
    justify-content: center;
    padding: 1rem;
    align-items: center;
    background-color: #fff;
    
    img{
        max-width: 100%;
        height: 5rem;
        object-fit: contain;
    }
}
.slick-slider {
    margin-bottom: 5rem;
}
.slick-next {
    right: 0px;
}
.slick-prev {
    left: 0px;
    
}
.slick-next:before {
    font-size: 28px;
  
}
.slick-prev:before {
    font-size: 28px; 
}

.slick-track{
    display: flex;
    justify-content: center;
    align-items: center;
}
.donor-name{
    font-size: 1.5rem;
}