.blog_image_wrap{
	img{
		width: 90%;
		max-height: 30rem;
		object-fit: cover;
	}
}

.blog_sidebar_right{
	border-top: 1px solid #fff;
}
// .blog_content_rt{
// 	p{
// 		width: 92%;
// 		@media screen and (max-width: 576px ) {
// 			width: 100%;
// 		  } 
// 	}
// } 
.blog-detail-photo-credit {
	font-size: 1.25rem;
	font-style: italic;
}