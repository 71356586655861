.input-wrapper{
	width: 50%;
	height: 4rem;
	border: 1px solid #3B4254;
	background: #1A2238;
	border-radius: 5px;

	input{
		width: calc( 100% - 60px );
		position: absolute;
		height: 100%;
		padding: 0 1rem;
		border: #fff;
		background: transparent;
		color: #fff !important;

		&:active, &:focus-visible{
			outline: none;
			border: 0;
		}

		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: #ccc;
			opacity: 1; /* Firefox */
			font-size: 1.1rem;
		  }
		  
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #fff;
			font-size: 1.1rem;
		}
		  
		&::-ms-input-placeholder { /* Microsoft Edge */
			color: #fff;
			font-size: 1.1rem;
		}
	}

	button{
		position: absolute;
		right: 0;
		color: #fff;
		width: 60px;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		border: 0;
		outline: none;
	}
}

@media screen and ( max-width: 767px ) {
	.input-wrapper{
		width: 100%;
	}
}