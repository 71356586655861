.report-column {
    display: flex;
    justify-content: center;
    img {
        width: 100%;
        height: 22rem;
        object-fit: contain;
    }
    a {
        margin-top: 1rem;
        font-family: "calibre";
        text-align: center;
        display: flex;
    }
}