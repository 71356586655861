.filter-wrapper {
    // margin-left: 9rem;
    .filter-column {
        .filter-input-wrapper {
                display: flex;
                input, select {
                    padding: 3%;
                    width: 95%;
                    border: 1px solid #fff;
                    background-color: #1A2238;
                    border-radius: 6px;
                    &::placeholder{
                        color: #bebebe;
                    }
                    
                }
                span {
                    position: relative;
                .fas{
                    position: absolute;
                    right: 15px;
                    top: 18px;
                }}
            
        }
        @media screen and (max-width: 576px ) {
            margin-bottom: 1rem; 
        }
    }
    .dropdown-column {
        .dropdown {
            .dropdown-toggle {
            padding: 3%;
            width: 95%;
            border: 1px solid rgb(158, 154, 154);
            &::after {
                margin-left: 22rem;
            }
            &:focus {
                box-shadow:  0 0 0 0.25rem rgba(165, 159, 192, 0.5);
            }
        }
        .btn {
            text-align: start;
        }
        }
    }
}
.date-wrapper {
    // margin-left: 9rem;
    position: relative;
    margin-bottom: 3rem;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 3rem;
    //     width: 83rem;
    //     height: 0;
    //     border-top: 1px solid;
    //     margin-left: 11px;
    // }
    span {
        font-family: "calibre";
        font-size: 20px;
    }
    .date-column {
        .dropdown {
            display: flex;
            justify-content: flex-end;
            // margin-right: 9rem;
          .btn-success {
              background-color: transparent;
              border-color: transparent;
              font-size: 20px;
              font-family: "calibre";
          }
          .btn-success.dropdown-toggle:focus {
              box-shadow: none;
          }
          .dropdown-toggle::after  {
              margin-left: 1rem;
          }
        }
    }
}
.publication-length{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
}