// Main color variables 

$primary :#BEBEBE;
$secondary : #A12AFE;
$success : #00CC83;
$info : #807A56;
$warning : #7D3E30;
$danger : #454545;
$light : #eee;
$dark : #ccc;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

$body-bg: rgba(0, 0, 0, 0);
$body-color: #BEBEBE;

$headings-font-family: 'gotham' !default;
$headings-font-weight:  bold !default;

// Font family  
$font-family-sans-serif: 'calibre',system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-weight-normal:          300 !default;
$font-weight-light:          100 !default;
$font-weight-bold:          400 !default;



// font size 
$font-size-base : 1.36rem !default;

$h1-font-size:                $font-size-base * 2.38 !default;
$h2-font-size:                $font-size-base * 1.75 !default;
$h3-font-size:                $font-size-base * 1.5 !default;
$h4-font-size:                $font-size-base * 1.25 !default;
$h5-font-size:                $font-size-base * 1 !default;
$h6-font-size:                $font-size-base !default;