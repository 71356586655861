.background {
    overflow: hidden;
    position: relative;
}

.background-svg {
    overflow: visible;
    position: absolute;
    /* width: 2326.336px; */
    width: 200vw;
    /* height: 4431.55px; */
    height: 300vw; 
    /* height: 235vw;  */
    /* left: -218.41px; */
    left: -50%;
    /* top: -881.883px; */
    top: -50%;
    transform: matrix(1, 0, 0, 1, 0, 0);
	z-index: -1;

  }

.background_gradient{
	height: 100%;
    background: linear-gradient(238.9deg, rgba(11,27,59,1) 0%, rgba(0,0,0,1) 44.34%, rgba(61,66,77,1) 100%);
	position: absolute;
	overflow: visible;
	width: 100%;
	z-index: -2;
}

#background-svg {
	fill: url(#background-svg);
}


@media screen and (max-width:992px) {
    .background-svg{
        top: -20%;
    }       
}

@media screen and (max-width:992px) {
    .background-svg{
        top: -20%;
    }       
}

@media screen and (max-width:576px) {
    .background-svg{
        height: 550vw;
    }       
}

@media screen and (max-width:400px) {
    .background-svg{
        height: 700vw;
        width: 400vw;
        left: -140%;
    }       
}