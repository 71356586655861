/* =======================================================================================
 =================================== GOTHAM FONT FACE ====================================
 ======================================================================================= */
@font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro-Light.eot');
	src: url('../assets/fonts/gotham/GothamPro-Light.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro-Light.woff') format("woff"),url('../assets/fonts/gotham/GothamPro-Light.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro-Light.svg#GothamPro-Light') format("svg"),url('../assets/fonts/gotham/GothamPro-Light.eot?#iefix') format("embedded-opentype");
	font-weight: 100;
	font-style: normal
  }
  
  @font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro-LightItalic.eot');
	src: url('../assets/fonts/gotham/GothamPro-LightItalic.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro-LightItalic.woff') format("woff"),url('../assets/fonts/gotham/GothamPro-LightItalic.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro-LightItalic.svg#GothamPro-LightItalic') format("svg"),url('../assets/fonts/gotham/GothamPro-LightItalic.eot?#iefix') format("embedded-opentype");
	font-weight: 100;
	font-style: italic
  }
  
  @font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro.eot');
	src: url('../assets/fonts/gotham/GothamPro.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro.woff') format("woff"),url('../assets/fonts/gotham/GothamPro.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro.svg#GothamPro') format("svg"),url('../assets/fonts/gotham/GothamPro.eot?#iefix') format("embedded-opentype");
	font-weight: 200;
	font-style: normal
  }
  
  @font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro-Italic.eot');
	src: url('../assets/fonts/gotham/GothamPro-Italic.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro-Italic.woff') format("woff"),url('../assets/fonts/gotham/GothamPro-Italic.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro-Italic.svg#GothamPro-Italic') format("svg"),url('../assets/fonts/gotham/GothamPro-Italic.eot?#iefix') format("embedded-opentype");
	font-weight: 200;
	font-style: italic
  }
  
  @font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro-Medium.eot');
	src: url('../assets/fonts/gotham/GothamPro-Medium.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro-Medium.woff') format("woff"),url('../assets/fonts/gotham/GothamPro-Medium.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro-Medium.svg#GothamPro-Medium') format("svg"),url('../assets/fonts/gotham/GothamPro-Medium.eot?#iefix') format("embedded-opentype");
	font-weight: 400;
	font-style: normal
  }
  
  @font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro-MediumItalic.eot');
	src: url('../assets/fonts/gotham/GothamPro-MediumItalic.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro-MediumItalic.woff') format("woff"),url('../assets/fonts/gotham/GothamPro-MediumItalic.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro-MediumItalic.svg#GothamPro-MediumItalic') format("svg"),url('../assets/fonts/gotham/GothamPro-MediumItalic.eot?#iefix') format("embedded-opentype");
	font-weight: 400;
	font-style: italic
  }
  
  @font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro-Bold.eot');
	src: url('../assets/fonts/gotham/GothamPro-Bold.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro-Bold.woff') format("woff"),url('../assets/fonts/gotham/GothamPro-Bold.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro-Bold.svg#GothamPro-Bold') format("svg"),url('../assets/fonts/gotham/GothamPro-Bold.eot?#iefix') format("embedded-opentype");
	font-weight: 600;
	font-style: normal
  }
  
  @font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro-BoldItalic.eot');
	src: url('../assets/fonts/gotham/GothamPro-BoldItalic.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro-BoldItalic.woff') format("woff"),url('../assets/fonts/gotham/GothamPro-BoldItalic.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro-BoldItalic.svg#GothamPro-BoldItalic') format("svg"),url('../assets/fonts/gotham/GothamPro-BoldItalic.eot?#iefix') format("embedded-opentype");
	font-weight: 600;
	font-style: italic
  }
  
  @font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro-Black.eot');
	src: url('../assets/fonts/gotham/GothamPro-Black.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro-Black.woff') format("woff"),url('../assets/fonts/gotham/GothamPro-Black.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro-Black.svg#GothamPro-Black') format("svg"),url('../assets/fonts/gotham/GothamPro-Black.eot?#iefix') format("embedded-opentype");
	font-weight: 800;
	font-style: normal
  }
  
  @font-face {
	font-family: "Gotham";
	src: url('../assets/fonts/gotham/GothamPro-BlackItalic.eot');
	src: url('../assets/fonts/gotham/GothamPro-BlackItalic.woff2') format("woff2"),url('../assets/fonts/gotham/GothamPro-BlackItalic.woff') format("woff"),url('../assets/fonts/gotham/GothamPro-BlackItalic.ttf') format("truetype"),url('../assets/fonts/gotham/GothamPro-BlackItalic.svg#GothamPro-BlackItalic') format("svg"),url('../assets/fonts/gotham/GothamPro-BlackItalic.eot?#iefix') format("embedded-opentype");
	font-weight: 800;
	font-style: italic
  }
  
  /* =======================================================================================
 =================================== CALIBRI FONT FACE ====================================
 ======================================================================================= */
 @font-face {font-family: "Calibre";
	src: url("../assets/fonts/calibre/Calibre.eot"); /* IE9*/
	src: url("../assets/fonts/calibre/Calibre.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
	url("../assets/fonts/calibre/Calibre.woff2") format("woff2"), /* chrome、firefox */
	url("../assets/fonts/calibre/Calibre.woff") format("woff"), /* chrome、firefox */
	url("../assets/fonts/calibre/Calibre.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
	url("../assets/fonts/calibre/Calibre.svg#Calibre") format("svg"); /* iOS 4.1- */
}
  /* =======================================================================================
 =================================== 'BEBAS NEUE' FONT FACE ====================================
 ======================================================================================= */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');